<template>
  <div class="jiaocheng">
    <title-top>新手教程</title-top>
    
    <img style="width:100%; height:100%" src="~images/sqjq.png" alt="">
  </div>
</template>

<script>
// @ is an alias to /src
import TitleTop from '@/components/common/title/TitleTop'

export default {
  name: 'JiaoCheng',
  data(){
    return {
      
    }
  },
  components: {
    TitleTop
  }
}
</script>
<style  scoped>
 


</style>
